<template>
  <ConfirmDialog
    :value.sync="show"
    title="Edytuj wpis"
    maxWidth="600px"
    :loading="loading"
    :persistent="loading"
    :compact="false"
    negativeButtonText="Anuluj"
    positiveButtonText="Zapisz"
    negativeButtonColor="primary"
    :positiveAction="onSaveClicked"
    :negativeAction="hide"
    showCloseBtn
    dontHideOnPositiveAction
  >
    <v-layout column align-center mb-2 slot="header" style="width: 100%">
      <DefaultLabel>{{ name }}</DefaultLabel>
    </v-layout>
    <v-form ref="form" slot="content">
      <v-layout column class="mx-4">
        <FieldWrapperDelegate
          v-if="!isDrawingField"
          :field.sync="field"
          :session="session"
          hideHeader
          clientOnlyPredefined
        ></FieldWrapperDelegate>
        <div class="text-subtitle-1 mb-1 mt-3 black--text">Powód edycji:</div>
        <v-flex xs12>
          <v-radio-group
            class="zg-pchded-radio-group zg-text-control"
            v-model="reason"
            :mandatory="false"
            :hide-details="true"
          >
            <v-radio
              class="zg-text-control"
              label="Uzupełnianie treści"
              :value="0"
            ></v-radio>
            <v-radio
              class="zg-text-control"
              label="Poprawa błędów ortograficznych"
              :value="1"
            ></v-radio>
            <v-radio
              class="zg-text-control"
              label="Pomyłka podczas wprowadzania tekstu"
              :value="2"
            ></v-radio>
            <v-layout full-width align-start>
              <v-radio
                class="zg-text-control"
                label="Inny:"
                :value="3"
                style="margin-top: 11px !important"
              ></v-radio>
              <v-text-field
                grow
                placeholder="Wpisz powód edycji..."
                :rules="textRules"
                class="default-input-style ml-2"
                outlined
                v-model="other"
                :disabled="reason != 3"
              ></v-text-field>
            </v-layout>
          </v-radio-group>
        </v-flex>
      </v-layout>
    </v-form>
  </ConfirmDialog>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import Validators from "@/mixins/Validators";
import PatientService from "@/services/patient.service";
import { DRAWING_FIELDS } from "@/constants/patientcard/fields/types";

export default {
  mixins: [ProxyCRUDMixin, Validators],
  props: {
    value: {},
    session: {},
    field: {},
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      reason: 0,
      other: null,
    };
  },
  components: {
    Message: () => import("@/components/Message"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    FieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/FieldWrapperDelegate"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    isDrawingField() {
      return DRAWING_FIELDS.includes(this.field.field_type);
    },
  },
  methods: {
    hide() {
      this.show = false;
    },
    onSaveClicked() {
      switch (this.reason) {
        case 0:
          this.field.reason = "Uzupełnianie treści";
          break;
        case 1:
          this.field.reason = "Poprawa błędów ortograficznych";
          break;
        case 2:
          this.field.reason = "Pomyłka podczas wprowadzania tekstu";
          break;
        case 3:
          if (this.$refs.form.validate()) {
            this.field.reason = this.other;
            break;
          } else {
            return;
          }
      }

      this.beforeRequest();
      PatientService.updateHistoryField(
        this.field.name,
        this.field
      )
        .then((reply) => {
          this.reset();
          this.$emit("edited", reply);
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    addRecords(records) {
      records.forEach((record) => {
        this.delegate.text += `<p>${record}</p>`;
      });
    },
  },
};
</script>

<style lang="css">
.zg-pchded-radio-group .v-input__control {
  width: 100% !important;
}
</style>

<style lang="css" scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>
